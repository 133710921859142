import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DropdownMenuDirective,
  DropdownModule,
  DropdownToggleDirective,
  FormGroupModule,
  IconComponent,
  InputDirective,
} from '@sb/ui';
import { addMinutes, subMinutes } from 'date-fns';
import { NgxMaskDirective } from 'ngx-mask';

import { format } from './date.helper';
import { TimepickerComponent } from './timepicker/timepicker.component';

const defaultValue = '00:00';

export const TIME_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimeInputComponent),
  multi: true,
};

@Component({
  selector: 'time-input',
  template: `
    <sb-form-group
      [sbDropdownToggle]="menu"
      tabindex="-1"
      (focus)="inputElement.focus()"
      [ngClass]="{
        'pointer-events-none': disabled
      }"
    >
      <input
        class="group-aria-invalid:border-red-500"
        #inputElement
        [id]="inputId"
        sbInput
        type="text"
        [ngModel]="value"
        (ngModelChange)="onChangeInput($event)"
        [ngModelOptions]="{ standalone: true }"
        (keydown.ArrowUp)="incrementTime($event)"
        (keydown.ArrowDown)="decrementTime($event)"
        [disabled]="disabled"
        [mask]="'Hh:m0'"
        [dropSpecialCharacters]="false"
        [outputTransformFn]="outputTransformFn"
        [showMaskTyped]="true"
        (blur)="onBlur()"
        (keydown.tab)="onInputTab()"
      />
      <div
        *ngIf="!hideInputAddon"
        sbFormGroupSuffix
        class="group-aria-invalid:border-red-500 group-aria-invalid:bg-red-25 group-aria-invalid:text-red-500"
      >
        <sb-icon [name]="'clock'"></sb-icon>
      </div>
    </sb-form-group>
    <ng-template #menu>
      <div sbDropdownMenu>
        <timepicker
          class="mx-2 block w-40"
          [ngModel]="timeStruct"
          (ngModelChange)="onChangeTimePicker($event)"
          [ngModelOptions]="{ standalone: true }"
          [minuteStep]="minuteSteps"
          [disabled]="disabled"
          [showMeridian]="false"
        ></timepicker>
      </div>
    </ng-template>
  `,
  providers: [TIME_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskDirective,
    DropdownModule,
    InputDirective,
    FormGroupModule,
    IconComponent,
    TimepickerComponent,
  ],
})
export class TimeInputComponent implements ControlValueAccessor {
  @HostBinding('class')
  protected readonly hostClass = 'group inline-block';

  @Input()
  @HostBinding('attr.aria-invalid')
  public hasError: boolean;

  /**
   * TODO temporary fix to handle components that compose this component and
   * potentially pass values that don't adhere to its interface / this methods signature
   * Currently tsc isn't complaining because we don't use strict mode.
   * Once we do, we can get rid of this fix and instead deal with it in the composing
   * components.
   */
  @Input()
  public set minuteSteps(value: number | string) {
    const steps = typeof value === 'string' ? parseInt(value, 10) : value;
    if (steps && typeof steps === 'number') {
      this._minuteSteps = steps;
    }
  }
  public get minuteSteps(): number {
    return this._minuteSteps;
  }
  private _minuteSteps = 15;

  @Input()
  public hideInputAddon: boolean;

  @Input()
  public inputId: string;

  @Input()
  @HostBinding('class.w-full')
  public fullWidth = false;

  @HostBinding('class.w-28')
  public get largeWidthClass() {
    return !this.fullWidth && !this.hideInputAddon;
  }

  @HostBinding('class.w-16')
  public get smallWidthClass() {
    return !this.fullWidth && this.hideInputAddon;
  }

  public value = defaultValue;
  public timeStruct: Date = this.timeToStruct(defaultValue);

  public disabled: boolean;

  @ViewChild(DropdownToggleDirective, { static: true, read: ElementRef })
  public dropdown?: ElementRef<HTMLButtonElement>;
  @ViewChild(DropdownMenuDirective, { static: false })
  public dropdownMenu?: DropdownMenuDirective;

  // internal functions to call when ControlValueAccessor
  // gets called
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched: () => any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onModelChange: (value: any) => void = () => {};

  public constructor(private cd: ChangeDetectorRef) {}

  public outputTransformFn(value: string) {
    if (!value) {
      return '';
    }
    // ensure the output is always padded with 0
    const parts = Array.from({ ...value.split(':'), length: 2 });
    return parts.map((part) => (part || '').padStart(2, '0')).join(':');
  }

  public onBlur() {
    // This looks strange but its needed to be able to display the masked value on blur
    const value = this.value;
    this.value = '';
    this.cd.detectChanges();
    this.value = value;
    this.onTouched();
  }

  // our custom onChange method
  public onChangeInput(value: string) {
    this.onChange(value);
  }

  public onInputTab() {
    this.dropdownMenu?.focusFirstElement();
  }

  // our custom onChange method
  public onChangeTimePicker(value: Date) {
    if (!this.timeStructChanged(value)) {
      return;
    }
    this.onTouched();
    this.onChange(this.timeStructToString(value));
  }

  private timeStructChanged(value: Date) {
    value.setSeconds(0);

    return value.getTime() !== this.timeStruct.getTime();
  }

  private onChange(value: string) {
    const timeStruct = this.timeToStruct(value);

    if (this.value === value && !this.timeStructChanged(timeStruct)) {
      return;
    }

    this.value = value;
    this.timeStruct = timeStruct;

    this.onModelChange(this.value);
  }

  // called by the reactive form control
  public registerOnChange(fn: () => void) {
    // assigns to our internal model change method
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // writes the value to the local component
  // that binds to the "value"
  public writeValue(value: string) {
    this.value = value;
    this.timeStruct = this.timeToStruct(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public incrementTime(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    let time = this.timeStringToDate(this.value);
    const steps = this.minuteSteps;

    time = addMinutes(time, steps);

    this.onChangeInput(format(time, 'HH:mm'));
    this.cd.detectChanges();
  }

  public decrementTime(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    let time = this.timeStringToDate(this.value);
    const steps = this.minuteSteps;

    time = subMinutes(time, steps);

    this.onChangeInput(format(time, 'HH:mm'));
  }

  private timeFromString(value: string) {
    const [hour, minute] = (value || defaultValue).split(':');
    return { hour, minute };
  }

  private timeStringToDate(value: string) {
    const { hour, minute } = this.timeFromString(value);
    const date = new Date();
    date.setHours(parseInt(hour, 10), parseInt(minute, 10), 0);
    return date;
  }

  private timeToStruct(value: string): Date {
    const { hour, minute } = this.timeFromString(value);
    const timeStruct = new Date();
    timeStruct.setHours(parseInt(hour, 10), parseInt(minute, 10), 0);
    return timeStruct;
  }

  private timeStructToString(value: Date) {
    const hours = value.getHours();
    const minutes = value.getMinutes();
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
}
