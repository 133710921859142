import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonComponent, IconButtonComponent, IconComponent, InputDirective } from '@sb/ui';

const defaultConfig = {
  hourStep: 1,
  minuteStep: 5,
  showMeridian: false,
  meridians: ['AM', 'PM'],
  readonlyInput: false,
  min: void 0,
  max: void 0,
};

export const TIMEPICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimepickerComponent),
  multi: true,
};

// todo: refactor directive has to many functions! (extract to stateless helper)
// todo: implement `time` validator
// todo: replace increment/decrement blockers with getters, or extract
// todo: unify work with selected
function isDefined(value: any): boolean {
  return typeof value !== 'undefined';
}

function addMinutes(date: any, minutes: number): Date {
  const dt = new Date(date.getTime() + minutes * 60000);
  const newDate = new Date(date);
  newDate.setHours(dt.getHours(), dt.getMinutes());
  return newDate;
}

function cloneDate(date: Date): Date {
  return new Date(date.getTime());
}

@Component({
  selector: 'timepicker',
  template: `
    <div
      class="grid-cols-timepicker grid text-center gap-4 children:flex children:flex-col children:items-center children:justify-center children:gap-3"
    >
      <div>
        <ng-container *ngIf="!readonlyInput">
          <button
            (click)="incrementHours($event)"
            [disabled]="noIncrementHours()"
            sb-icon-button
            [color]="'transparent'"
          >
            <sb-icon [name]="'chevron-up'"></sb-icon>
          </button>
        </ng-container>

        <input
          class="max-w-12 text-center"
          sbInput
          [hasError]="invalidHours"
          [(ngModel)]="hours"
          [ngModelOptions]="{ standalone: true }"
          (change)="updateHours()"
          [readonly]="readonlyInput"
          (blur)="hoursOnBlur()"
          (keydown.ArrowUp)="incrementHours($event)"
          (keydown.ArrowDown)="decrementHours($event)"
          maxlength="2"
        />

        <ng-container *ngIf="!readonlyInput">
          <button
            (click)="decrementHours($event)"
            [disabled]="noDecrementHours()"
            sb-icon-button
            [color]="'transparent'"
          >
            <sb-icon [name]="'chevron-down'"></sb-icon>
          </button>
        </ng-container>
      </div>
      <div><span>:</span></div>
      <div>
        <ng-container *ngIf="!readonlyInput">
          <button
            (click)="incrementMinutes($event)"
            [disabled]="noIncrementMinutes()"
            sb-icon-button
            [color]="'transparent'"
          >
            <sb-icon [name]="'chevron-up'"></sb-icon>
          </button>
        </ng-container>

        <div class="flex gap-2">
          <input
            class="max-w-12 text-center"
            sbInput
            [hasError]="invalidMinutes"
            [(ngModel)]="minutes"
            [ngModelOptions]="{ standalone: true }"
            (change)="updateMinutes()"
            [readonly]="readonlyInput"
            (blur)="minutesOnBlur()"
            (keydown.ArrowUp)="incrementMinutes($event)"
            (keydown.ArrowDown)="decrementMinutes($event)"
            maxlength="2"
          />

          <button
            *ngIf="showMeridian"
            sb-button
            [color]="'secondary'"
            [disabled]="noToggleMeridian() || readonlyInput"
            (click)="toggleMeridian()"
          >
            {{ meridian }}
          </button>
        </div>

        <ng-container *ngIf="!readonlyInput">
          <button
            (click)="decrementMinutes($event)"
            [disabled]="noDecrementMinutes()"
            sb-icon-button
            [color]="'transparent'"
          >
            <sb-icon [name]="'chevron-down'"></sb-icon>
          </button>
        </ng-container>
      </div>
    </div>
  `,
  providers: [TIMEPICKER_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [IconComponent, FormsModule, IconButtonComponent, CommonModule, InputDirective, ButtonComponent],
})
export class TimepickerComponent implements ControlValueAccessor, OnChanges {
  /** hours change step */
  @Input()
  public hourStep: number = defaultConfig.hourStep;
  /** hours change step */
  @Input()
  public minuteStep: number = defaultConfig.minuteStep;
  /** if true hours and minutes fields will be readonly */
  @Input()
  public readonlyInput: boolean = defaultConfig.readonlyInput;

  /** minimum time user can select */
  @Input()
  public min: Date = defaultConfig.min;
  /** maximum time user can select */
  @Input()
  public max: Date = defaultConfig.max;
  /** meridian labels based on locale */
  @Input()
  public meridians: string[] = defaultConfig.meridians;

  /** if true works in 12H mode and displays AM/PM. If false works in 24H mode and hides AM/PM */
  @Input()
  public get showMeridian(): boolean {
    return this._showMeridian;
  }

  public set showMeridian(value: boolean) {
    this._showMeridian = value;

    this.updateTemplate();
  }

  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;

  // input values
  public hours: string;
  public minutes: string;

  // validation
  public invalidHours: any;
  public invalidMinutes: any;

  public meridian: any; // ??

  // result value
  protected _selected: Date = new Date();
  protected _showMeridian: boolean = defaultConfig.showMeridian;

  protected get selected(): Date {
    return this._selected;
  }

  protected set selected(v: Date) {
    if (v) {
      this._selected = v;
      this.updateTemplate();
      this.onChange(this.selected);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['minuteStep'].currentValue === null) {
      this.minuteStep = defaultConfig.minuteStep;
    }
  }

  public writeValue(v: any): void {
    if (v === this.selected) {
      return;
    }
    if (v && v instanceof Date) {
      this.selected = v;
      return;
    }
    this.selected = v ? new Date(v) : void 0;
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.readonlyInput = isDisabled;
  }

  public updateHours(): void {
    if (this.readonlyInput) {
      return;
    }

    const hours = this.getHoursFromTemplate();
    const minutes = this.getMinutesFromTemplate();
    this.invalidHours = !isDefined(hours);
    this.invalidMinutes = !isDefined(minutes);

    if (this.invalidHours || this.invalidMinutes) {
      // TODO: needed a validation functionality.
      return;
      // todo: validation?
      // invalidate(true);
    }

    const changedDate = cloneDate(this.selected);
    changedDate.setHours(hours);

    this.selected = changedDate;

    this.invalidHours = this.selected < this.min || this.selected > this.max;
    if (this.invalidHours) {
      // todo: validation?
      // invalidate(true);
      return;
    } else {
      this.refresh(/*'h'*/);
    }
  }

  public hoursOnBlur(): void {
    if (this.readonlyInput) {
      return;
    }

    // todo: binded with validation
    if (!this.invalidHours && parseInt(this.hours, 10) < 10) {
      this.hours = this.pad(this.hours);
    }
  }

  public updateMinutes(): void {
    if (this.readonlyInput) {
      return;
    }

    const minutes = this.getMinutesFromTemplate();
    const hours = this.getHoursFromTemplate();
    this.invalidMinutes = !isDefined(minutes);
    this.invalidHours = !isDefined(hours);

    if (this.invalidMinutes || this.invalidHours) {
      // TODO: needed a validation functionality.
      return;
      // todo: validation
      // invalidate(undefined, true);
    }

    const changedDate = cloneDate(this.selected);
    changedDate.setMinutes(minutes);

    this.selected = changedDate;

    this.invalidMinutes = this.selected < this.min || this.selected > this.max;
    if (this.invalidMinutes) {
      // todo: validation
      // invalidate(undefined, true);
      return;
    } else {
      this.refresh(/*'m'*/);
    }
  }

  public minutesOnBlur(): void {
    if (this.readonlyInput) {
      return;
    }

    if (!this.invalidMinutes && parseInt(this.minutes, 10) < 10) {
      this.minutes = this.pad(this.minutes);
    }
  }

  public incrementHours(event: MouseEvent | KeyboardEvent): void {
    event.preventDefault();
    if (!this.noIncrementHours()) {
      this.addMinutesToSelected(this.hourStep * 60);
    }
  }

  public decrementHours(event: MouseEvent | KeyboardEvent): void {
    event.preventDefault();
    if (!this.noDecrementHours()) {
      this.addMinutesToSelected(-this.hourStep * 60);
    }
  }

  public incrementMinutes(event: MouseEvent | KeyboardEvent): void {
    event.preventDefault();

    if (!this.noIncrementMinutes()) {
      this.addMinutesToSelected(this.minuteStep);
    }
  }

  public decrementMinutes(event: MouseEvent | KeyboardEvent): void {
    event.preventDefault();
    if (!this.noDecrementMinutes()) {
      this.addMinutesToSelected(-this.minuteStep);
    }
  }

  public noIncrementHours(): boolean {
    const incrementedSelected = addMinutes(this.selected, this.hourStep * 60);
    return incrementedSelected > this.max || (incrementedSelected < this.selected && incrementedSelected < this.min);
  }

  public noDecrementHours(): boolean {
    const decrementedSelected = addMinutes(this.selected, -this.hourStep * 60);
    return decrementedSelected < this.min || (decrementedSelected > this.selected && decrementedSelected > this.max);
  }

  public noIncrementMinutes(): boolean {
    const incrementedSelected = addMinutes(this.selected, this.minuteStep);
    return incrementedSelected > this.max || (incrementedSelected < this.selected && incrementedSelected < this.min);
  }

  public noDecrementMinutes(): boolean {
    const decrementedSelected = addMinutes(this.selected, -this.minuteStep);
    return decrementedSelected < this.min || (decrementedSelected > this.selected && decrementedSelected > this.max);
  }

  public toggleMeridian(): void {
    if (!this.noToggleMeridian()) {
      const sign = this.selected.getHours() < 12 ? 1 : -1;
      this.addMinutesToSelected(12 * 60 * sign);
    }
  }

  public noToggleMeridian(): boolean {
    if (this.readonlyInput) {
      return true;
    }

    if (this.selected.getHours() < 13) {
      return addMinutes(this.selected, 12 * 60) > this.max;
    } else {
      return addMinutes(this.selected, -12 * 60) < this.min;
    }
  }

  protected refresh(/*type?:string*/): void {
    this.updateTemplate();
    this.onChange(this.selected);
  }

  protected updateTemplate(/*keyboardChange?:any*/): void {
    let hours = this.selected.getHours();
    const minutes = this.selected.getMinutes();

    if (this.showMeridian) {
      // Convert 24 to 12 hour system
      hours = hours === 0 || hours === 12 ? 12 : hours % 12;
    }

    this.hours = this.pad(hours);
    this.minutes = this.pad(minutes);

    if (!this.meridians) {
      this.meridians = defaultConfig.meridians;
    }

    this.meridian = this.selected.getHours() < 12 ? this.meridians[0] : this.meridians[1];
  }

  protected getHoursFromTemplate(): number {
    let hours = parseInt(this.hours, 10);
    const valid = this.showMeridian ? hours > 0 && hours < 13 : hours >= 0 && hours < 24;
    if (!valid) {
      return void 0;
    }

    if (this.showMeridian) {
      if (hours === 12) {
        hours = 0;
      }
      if (this.meridian === this.meridians[1]) {
        hours = hours + 12;
      }
    }
    return hours;
  }

  protected getMinutesFromTemplate(): number {
    const minutes = parseInt(this.minutes, 10);
    return minutes >= 0 && minutes < 60 ? minutes : undefined;
  }

  protected pad(value: string | number): string {
    return isDefined(value) && value.toString().length < 2 ? '0' + value : value.toString();
  }

  protected addMinutesToSelected(minutes: any): void {
    this.selected = addMinutes(this.selected, minutes);
    this.refresh();
  }
}
